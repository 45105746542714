// libraries
import { doc, getDoc } from "firebase/firestore";
import { firestoreRegionDb } from "../firebase.service";

// constants
import { GLOBAL_USERS_COLLECTION } from "wombat-global/src/constants";
import { IGlobalUserEntity } from "wombat-global/src/typings/users";

export async function getGlobalUserDocumentFromCache(
  userId: string,
): Promise<IGlobalUserEntity> {
  return getDoc(
    doc(firestoreRegionDb.us, GLOBAL_USERS_COLLECTION, userId),
  ).then((document) => {
    // console.log("getGlobalUserMetadata: ", document.metadata);
    return document.data() as IGlobalUserEntity;
  });
}
