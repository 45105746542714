// constants
import { ADDRESS_FORM_FIELDS } from "./address-form";

// types
import { EntityStatus, IFieldGroupBuilt, IFieldTypes } from "../../typings";

export const EDIT_TENANT_FORM: IFieldGroupBuilt = {
  name: "editTenant",
  label: "Company Name",
  groups: [
    {
      name: "tenant",
      label: "Basic Information",
      isSection: true,
      fields: [
        {
          name: "name",
          span: 1,
          label: "Company Name",
          fieldType: IFieldTypes.textField,
        },
        {
          name: "id",
          span: 1,
          label: "Short Code",
          fieldType: IFieldTypes.textField,
          readonly: true,
        },
        {
          name: "timeZone",
          span: 1,
          label: "Time Zone",
          fieldType: IFieldTypes.timezone,
        },
        {
          name: "local",
          label: "Data Residency",
          span: 1,
          readonly: true,
          fieldType: IFieldTypes.select,
          options: [
            {
              label: "Canada",
              value: "ca",
            },
            {
              label: "United States",
              value: "us",
            },
          ],
        },
      ],
    },
    {
      ...ADDRESS_FORM_FIELDS,
      name: "tenant.address",
      min: 1,
      max: 1,
      label: "Head Office Address",
    },
    {
      name: "tenant.contactInformation",
      label: "Contact Information",
      min: 1,
      max: 1,
      fields: [
        {
          fieldType: IFieldTypes.textField,
          span: 2,
          name: "name",
          label: "Primary Contact Full Name",
        },
        {
          name: "email",
          span: 3,
          label: "Email",
          fieldType: IFieldTypes.emailField,
        },
        {
          name: "officePhone",
          span: 2,
          label: "Office Phone",
          fieldType: IFieldTypes.phoneField,
        },
        {
          name: "mobilePhone",
          span: 1,
          label: "Mobile Phone",
          fieldType: IFieldTypes.phoneField,
        },
        {
          name: "showSupportInToolbar",
          span: 4,
          label: "Show support info in toolbar",
          fieldType: IFieldTypes.checkbox,
          conditions: [
            {
              parameter: "tenant.contactInformation.0.supportPhone",
              operation: "==",
              value: "",
              action: "hidden",
            },
          ],
        },
        {
          name: "supportEmail",
          span: 3,
          label: "Support Email",
          fieldType: IFieldTypes.emailField,
        },
        {
          name: "showEmailInSupport",
          span: 1,
          label: "Show in support",
          fieldType: IFieldTypes.checkbox,
          conditions: [
            {
              parameter: "tenant.contactInformation.0.supportEmail",
              operation: "==",
              value: "",
              action: "hidden",
            },
          ],
        },
        {
          name: "supportPhone",
          span: 3,
          label: "Support Phone",
          fieldType: IFieldTypes.phoneField,
        },
        {
          name: "showPhoneInSupport",
          span: 1,
          label: "Show in support",
          fieldType: IFieldTypes.checkbox,
          conditions: [
            {
              parameter: "tenant.contactInformation.0.supportPhone",
              operation: "==",
              value: "",
              action: "hidden",
            },
          ],
        },
        {
          name: "supportPortalUrl",
          span: 3,
          label: "Support Portal URL",
          fieldType: IFieldTypes.textField,
        },
        {
          name: "showPortalUrlInSupport",
          span: 1,
          label: "Show in support",
          fieldType: IFieldTypes.checkbox,
          conditions: [
            {
              parameter: "tenant.contactInformation.0.supportPortalUrl",
              operation: "==",
              value: "",
              action: "hidden",
            },
          ],
        },
      ],
    },
  ],
};

export const ADD_TENANT_FORM: IFieldGroupBuilt = {
  name: "addTenant",
  label: "Add Client",
  fields: [
    {
      name: "name",
      span: 1,
      label: "Company Name",
      fieldType: IFieldTypes.textField,
      required: true,
    },
    {
      name: "id",
      span: 1,
      label: "Short Name",
      fieldType: IFieldTypes.textField,
      required: true,
    },
    {
      name: "timeZone",
      span: 1,
      label: "Time Zone",
      fieldType: IFieldTypes.timezone,
      required: true,
    },
    {
      name: "status",
      label: "Status",
      span: 1,
      fieldType: IFieldTypes.select,
      defaultValue: [EntityStatus.active],
      options: [
        {
          label: "Inactive",
          value: EntityStatus.inactive,
        },
        {
          label: "Active",
          value: EntityStatus.active,
        },
      ],
    },
    {
      name: "local",
      label: "Data Residency",
      span: 1,
      fieldType: IFieldTypes.select,
      defaultValue: ["ca"],
      options: [
        {
          label: "Canada",
          value: "ca",
        },
        {
          label: "United States",
          value: "us",
        },
      ],
    },
  ],
  groups: [
    {
      name: "contactInformation",
      label: "Contact Information",
      min: 1,
      max: 1,
      fields: [
        {
          fieldType: IFieldTypes.textField,
          span: 1,
          name: "name",
          label: "Primary Contact Full Name",
        },
        {
          name: "email",
          span: 1,
          label: "Email",
          fieldType: IFieldTypes.emailField,
        },
        {
          name: "officePhone",
          span: 1,
          label: "Office Phone",
          fieldType: IFieldTypes.phoneField,
        },
        {
          name: "mobilePhone",
          span: 1,
          label: "Mobile Phone",
          fieldType: IFieldTypes.phoneField,
        },
        {
          name: "showSupportInToolbar",
          span: 4,
          label: "Show support info in toolbar",
          fieldType: IFieldTypes.checkbox,
          conditions: [
            {
              parameter: "tenant.contactInformation.0.supportPhone",
              operation: "==",
              value: "",
              action: "hidden",
            },
          ],
        },
        {
          name: "supportEmail",
          span: 3,
          label: "Support Email",
          fieldType: IFieldTypes.emailField,
        },
        {
          name: "showEmailInSupport",
          span: 1,
          label: "Show in support",
          fieldType: IFieldTypes.checkbox,
          conditions: [
            {
              parameter: "tenant.contactInformation.0.supportEmail",
              operation: "==",
              value: "",
              action: "hidden",
            },
          ],
        },
        {
          name: "supportPhone",
          span: 3,
          label: "Support Phone",
          fieldType: IFieldTypes.phoneField,
        },
        {
          name: "showPhoneInSupport",
          span: 1,
          label: "Show in support",
          fieldType: IFieldTypes.checkbox,
          conditions: [
            {
              parameter: "tenant.contactInformation.0.supportPhone",
              operation: "==",
              value: "",
              action: "hidden",
            },
          ],
        },
        {
          name: "supportPortalUrl",
          span: 3,
          label: "Support Portal URL",
          fieldType: IFieldTypes.textField,
        },
        {
          name: "showPortalURLInSupport",
          span: 1,
          label: "Show in support",
          fieldType: IFieldTypes.checkbox,
          conditions: [
            {
              parameter: "tenant.contactInformation.0.supportPortalUrl",
              operation: "==",
              value: "",
              action: "hidden",
            },
          ],
        },
      ],
    },
    {
      ...ADDRESS_FORM_FIELDS,
      name: "address",
      min: 1,
      max: 1,
    },
  ],
};
