import publicInfraConfig from "@wombat/constants/src/public-regions.json";

export function getProjectInfraSettings(projectId?: string) {
  const defaultPubRegions = publicInfraConfig.default;
  const pubProjectConfig =
    publicInfraConfig[
      (projectId as "wombat-development" | "wombat-production") || "default"
    ];
  const us = { ...defaultPubRegions.us, ...pubProjectConfig.us };

  const ca = {
    ...defaultPubRegions.ca,
    ...pubProjectConfig.ca,
  };

  return {
    us,
    ca,
  };
}
