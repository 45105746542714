/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { ADMIN } from "../permission.maps";

// types
import { IClientTarget, ISiteTarget, ITenantTarget } from "../typings/generics";
import { PermissionsClaim } from "../typings/permissions";

export function hasWombatStaffPermissions(
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  claim: any,
  bitFlag: number,
): boolean {
  if (!claim) {
    return false;
  }
  return claim.perm && (claim.perm._p & bitFlag) == bitFlag;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function hasWombatStaffAdminPermissions(claim: any): boolean {
  if (!claim) {
    return false;
  }
  return claim.perm && (claim.perm._p & ADMIN.bitFlag) == ADMIN.bitFlag;
}

/**
 * Notes:
 * - tests are for this are ran within the api test suite
 */
export const hasAdminPermissions = (
  target: IClientTarget | ISiteTarget | ITenantTarget,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  claim: any,
): boolean => {
  const {
    orgId: o,
    tenantId: t,
    divisionId: d,
    siteId: s,
  } = target as unknown as Record<string, string>;

  if (!claim || !claim.perm) {
    return false;
  }

  const siteClaim = claim.perm[siteKey(s)];
  if (s && siteClaim) {
    return (siteClaim?._p & ADMIN.bitFlag) === ADMIN.bitFlag;
  }

  const rootTenantClaim = claim.perm[tenantKey(t)];
  // return true if admin at the tenant but checking admin at the division;
  if (t && rootTenantClaim) {
    return (
      (rootTenantClaim?._p & ADMIN.bitFlag) === ADMIN.bitFlag ||
      (Boolean(d) && (rootTenantClaim[d]?._p & ADMIN.bitFlag) === ADMIN.bitFlag)
    );
  }

  const hasOrgClaim = claim.perm && claim.perm[o];
  const hasTenantClaim = hasOrgClaim && t && claim.perm[o][t];
  const hasDivisionClaim = hasTenantClaim && t && d && claim.perm[o][t][d];

  // permission target is to the org
  if (hasOrgClaim) {
    return (claim.perm[o]?._p & ADMIN.bitFlag) === ADMIN.bitFlag;
  }

  // permission target is to the tenant
  if (hasTenantClaim && t) {
    return (claim.perm[o][t]?._p & ADMIN.bitFlag) === ADMIN.bitFlag;
  }

  // permission target is to the division
  // @todo this will fail if i have admin at the tenant but im checking for admin at the division
  if (hasDivisionClaim && t && d) {
    return (claim.perm[o][t][d]?._p & ADMIN.bitFlag) === ADMIN.bitFlag;
  }
  return false;
};

function siteKey(siteId: string) {
  return "s_" + siteId;
}

function tenantKey(tenantId: string) {
  return "t_" + tenantId;
}
export const sitePermissionKey = siteKey;
export const tenantPermissionKey = tenantKey;

export const getCurrentBitFlag = (
  target: IClientTarget | ISiteTarget | ITenantTarget,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  claim: any,
): number => {
  const {
    orgId: o,
    tenantId: t,
    divisionId: d,
    siteId: s,
  } = target as unknown as Record<string, string>;

  if (!claim || !claim.perm) {
    return 0;
  }

  const siteClaim = claim.perm[siteKey(s)];
  if (s && siteClaim) {
    return siteClaim?._p || 0;
  }

  const rootTenantClaim = claim.perm[tenantKey(t)];
  // permission target is to the tenant
  if (t && !d && rootTenantClaim) {
    return rootTenantClaim?._p || 0;
  }
  // permission target is to the division
  if (t && d && rootTenantClaim) {
    return rootTenantClaim[d]?._p || 0;
  }

  const hasOrgClaim = claim.perm && claim.perm[o];
  const hasTenantClaim = hasOrgClaim && t && claim.perm[o][t];
  const hasDivisionClaim = hasTenantClaim && t && d && claim.perm[o][t][d];

  // permission target is to the org
  if (hasOrgClaim && !t) {
    return claim.perm[o]?._p || 0;
  }

  // permission target is to the tenant
  if (hasTenantClaim && t && !d) {
    return claim.perm[o][t]?._p || 0;
  }

  // permission target is to the division
  if (hasDivisionClaim && t && d) {
    return claim.perm[o][t][d]?._p || 0;
  }

  return 0;
};

export const hasPermission = (
  target: IClientTarget | ISiteTarget | ITenantTarget | undefined,
  permissionClaim: PermissionsClaim,
  bitFlag: number,
): boolean => {
  // check for staff access
  if (hasWombatStaffAdminPermissions(permissionClaim)) {
    return true;
  }

  if (!target) {
    return false;
  }

  // if any of the targets parents are admin then the user has permissions
  if (hasAdminPermissions(target, permissionClaim)) {
    return true;
  }

  const userPermissionFlag = getCurrentBitFlag(target, permissionClaim);
  if (!userPermissionFlag) {
    return false;
  }
  return (userPermissionFlag & bitFlag) === bitFlag;
};
