// utilities
import { hasPermission } from "wombat-global/src/utilities/permissions";

// types
import { READ } from "wombat-global/src/permission.maps";
import {
  DivisionType,
  OrganizationType,
  PermissionsClaim,
  TenantType,
} from "wombat-global/src/typings/permissions";
import { IClientTarget, ISiteTarget } from "wombat-global/src/typings";

function notPermission<T>(args: [string, unknown]): args is [string, T] {
  const [id, _val] = args;
  return id !== "_p";
}

export type TargetClaim =
  | (IClientTarget & {
      perm: number | undefined;
    })
  | (ISiteTarget & {
      perm: number | undefined;
    });

/**
 * From the permission claim get the organizations with read access
 * @todo: this NEEDS unit testing for all different types of permissions and shapes
 *
 * Note:
 *  - this returns the highest permission first.
 */
export const getAuthorizationTargetsFromClaim = (
  claim: PermissionsClaim,
): TargetClaim[] => {
  const organizationKeys: TargetClaim[] = [];

  if (!claim.perm) {
    return organizationKeys;
  }

  // set list of organizations the active user has access to
  Object.entries(claim.perm)
    .filter(notPermission<OrganizationType>)
    .forEach(([path, permTarget]) => {
      const [orgId] = path.split(".");

      if (orgId.startsWith("s_")) {
        organizationKeys.push({
          siteId: orgId.replaceAll("s_", ""),
          perm: permTarget._p,
        });
        return;
      }

      if (orgId.startsWith("t_")) {
        organizationKeys.push({
          tenantId: orgId.replaceAll("t_", ""),
          perm: permTarget._p,
        });
        return;
      }

      const tenantAccessPoints = Object.entries(permTarget).filter(
        notPermission<TenantType>,
      );

      // show org access when orgPerm is more than just read, OR is read and no tenants
      if (
        (!tenantAccessPoints.length && hasPermission({ orgId }, claim, READ.bitFlag)) || // prettier-ignore
        (permTarget._p && permTarget._p > READ.bitFlag)
      ) {
        organizationKeys.push({ orgId, perm: permTarget._p });
      }

      // if (((organizationPerms as any)._p & READ.bitFlag) === READ.bitFlag) {
      //   if (hasPermission({ orgId }, claim, ADMIN.bitFlag)) {
      //     organizationKeys.push({ orgId, perm: organizationPerms._p });
      //   }
      // }

      tenantAccessPoints.forEach(([tenantId, tenantPerms]) => {
        const divAccessPoints = Object.entries(tenantPerms).filter(
          notPermission<DivisionType>,
        );

        // show tenant access when tenantPerm is more than just read, OR is read and no divisions
        if (
          (!divAccessPoints.length && hasPermission({ orgId, tenantId }, claim, READ.bitFlag)) || // prettier-ignore
          (tenantPerms._p && tenantPerms._p > READ.bitFlag)
        ) {
          organizationKeys.push({
            orgId,
            tenantId,
            perm: tenantPerms._p,
          });
        }

        /**
         * @todo: when implementing divisions, parse out division access and redirect
         */
      });
    });

  return organizationKeys.sort((a, b) => {
    return b.perm === -1
      ? 1
      : Number(b.perm) === Number(a.perm)
        ? // eslint-disable-next-line @typescript-eslint/no-explicit-any
          !(b as any).orgId && (a as any).orgId
          ? 1
          : -1
        : 0;
  });
};
