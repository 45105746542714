import { doc, Firestore, getDoc } from "firebase/firestore";
import { getAnalytics } from "firebase/analytics";
import { getPerformance } from "firebase/performance";
import { getRemoteConfig } from "firebase/remote-config";

// utilities
import { getGlobalUserAccessKey } from "wombat-global/src/utilities";

const env = import.meta.env;

import { _firebase, firestoreRegionDb } from "./firebase.init";

// types
import {
  TargetMetaDocument,
  WombatRegions,
} from "wombat-global/src/typings/multi-region";
import {
  IClientTarget,
  ISiteTarget,
  ITenantTarget,
} from "wombat-global/src/typings";
import { TARGET_META_COLLECTION } from "wombat-global/src/constants";

/**
 * Split out firebase init so that all utilities that require 'window' are in this `.service`, and others are in `.init`
 */
export * from "./firebase.init";

/**
 * analytics setup
 *
 * MEASUREMENT_ID must be set up within the firebase console and then added to the CICD pipeline.
 */
if (env.REACT_APP_MEASUREMENT_ID) {
  getAnalytics(_firebase);
}

// performance will be set up within the firebase console
export const performance = getPerformance(_firebase);

// Initialize Remote Config and get a reference to the service
export const remoteConfig = getRemoteConfig(_firebase);

// export default _firebase;

export async function generateFirestoreConnection(
  local: WombatRegions,
): Promise<Firestore>;
export async function generateFirestoreConnection(
  target: IClientTarget | ISiteTarget | ITenantTarget | undefined,
): Promise<Firestore>;
export async function generateFirestoreConnection(
  target:
    | IClientTarget
    | ISiteTarget
    | ITenantTarget
    | WombatRegions
    | undefined,
): Promise<Firestore> {
  if (!target) {
    return firestoreRegionDb.us;
  }

  if (typeof target === "string") {
    return firestoreRegionDb[target] || firestoreRegionDb.us;
  }

  const targetMeta = await getDoc(
    doc(
      firestoreRegionDb.us,
      [TARGET_META_COLLECTION, getGlobalUserAccessKey(target)].join("/"),
    ),
  ).then((d) => d.data() as TargetMetaDocument | undefined);
  return firestoreRegionDb[targetMeta?.local || "us"] || firestoreRegionDb.us;
}
