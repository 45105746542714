// libraries
import { Timestamp } from "@google-cloud/firestore";
import {
  ALL_WEEKDAYS,
  ByWeekday,
  Frequency,
  Options,
  RRule,
  Weekday,
  WeekdayStr,
  rrulestr,
} from "rrule";

// utilities
import {
  Duration,
  dateOrTimestampAsDate,
  dueDateFormToDuration,
  durationToQuantity,
  durationToUnit,
  isDate,
} from "../../utilities/time";
import { isObject } from "../../utilities";

// types
import { RRuleOptions, Schedule } from "./scheduler-core";
import {
  SchemasReferencesRecord,
  recurrenceToSimplifiedFreq,
  simpleFreqToRRule,
} from "../../typings";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import tz from "dayjs/plugin/timezone";

dayjs.extend(utc);
dayjs.extend(tz);

export type DateFixed = {
  dtType: "fixed";
  dateTime: Date | Timestamp;
};

export type DateRelative = {
  dtType: "relative";
  duration: Duration;
};

// FixedOrRelative
export type DateFixedOrRelative = DateFixed | DateRelative;

export function isFixedDate(
  dueDate: DateFixedOrRelative,
): dueDate is DateFixed {
  if (!isObject(dueDate)) {
    return false;
  }

  const typ = dueDate.dtType;
  if (typ !== "fixed") {
    return false;
  }
  const vIsDate = isDate(dateOrTimestampAsDate(dueDate.dateTime));
  return typ === "fixed" && vIsDate;
}

export function isRelativeDate(
  dueDate: DateFixedOrRelative,
): dueDate is DateRelative {
  if (!isObject(dueDate)) {
    return false;
  }

  const typ = dueDate.dtType;

  if (typ !== "relative") {
    return false;
  }

  return typ === "relative" && typeof dueDate["duration"] === "object";
}

export type ScheduleFormPayload = {
  assignedTo?: string[];
  assignedToRole?: string;
  formPerUser?: boolean;
  form: {
    schemaId: string;
  };
  dueDate?: DateRelative;
};

// We don't explicitly type the taskPayload in schedule-core because the core types are agnostic
// of the contents of the payload. We type it here as an end user of the scheduling system.
export type ScheduleType = Schedule & {
  // Limit the task payload to specific types
  taskPayload: ScheduleFormPayload;
};

type EndsType = "never" | "on" | "after";

export type ScheduleCreateForm = Omit<
  ScheduleType,
  "id" | "recurrence" | "paused" | "pausedUntil" | "startDate"
> & {
  // delete this when merging
  recurring?: boolean;
  startDate?: string; // Input from the frontend will be a Date
  _timeOfDay: string;
  _recurrence?: Omit<
    RRuleOptions,
    "bymonth" | "byweekday" | "bysetpos" | "byhour" | "tzid" | "until"
  > & {
    bymonth?: string[];
    byweekday?: string[];
    bysetpos?: number;
    until?: string;

    _ends?: EndsType;

    _weekday?: string[];
  };

  _dueDate: "none" | "overdue-after";
  _dueDateDuration?: number;
  /**
   * "min" is only allowed in dev environments
   */
  _dueDateUnit?: "h" | "d" | "w" | "m" | "y";

  _assignType?: "user-each" | "user-shared" | "role-each" | "role-shared";
};

export type ScheduleEditForm = Omit<
  ScheduleCreateForm,
  "id" | "startDate" | "timeZone" | "pastScheduledRuns"
>;

export type AllScheduleReferenceMetadata = Pick<
  ScheduleType,
  | "id"
  | "name"
  | "startDate"
  | "end"
  | "taskPayload"
  | "recurrence"
  | "paused"
  | "pausedUntil"
  | "nextScheduledDate"
  | "taskType"
  | "frequencySimplified"
>;

export type AllSchedulesRecord =
  SchemasReferencesRecord<AllScheduleReferenceMetadata>;

const monthToIndex: Record<string, number> = {
  january: 1,
  february: 2,
  march: 3,
  april: 4,
  may: 5,
  june: 6,
  july: 7,
  august: 8,
  september: 9,
  october: 10,
  november: 11,
  december: 12,
};

// Add 1 to get the 1 based index of the months
const indexToMonth: string[] = [
  "january",
  "february",
  "march",
  "april",
  "may",
  "june",
  "july",
  "august",
  "september",
  "october",
  "november",
  "december",
];

/**
 * Utility functions
 *
 * @todo move these to the utils
 */

export function recurrenceSectionToRRule(
  startDate: string | undefined,
  recurrence: ScheduleCreateForm["_recurrence"],
  timeZone?: string,
): RRule | undefined {
  if (!recurrence) {
    return;
  }
  if (!timeZone) {
    timeZone = dayjs.tz.guess();
  }
  const opts = structuredClone(recurrence);
  opts.freq = parseInt(opts.freq?.toString());
  if (isNaN(opts.freq)) {
    return;
  }

  // const months: string[] | undefined = recurrence.bymonth;
  let bymonth: number[] | null = null;
  if (opts.bymonth) {
    bymonth = opts.bymonth.map((m) => monthToIndex[m]);
    if (bymonth.length === 0) {
      bymonth = null;
    }
  }

  // RRule bug requires handling weekday opts in a specific way
  // https://github.com/jkbrzt/rrule/issues/102
  // https://github.com/jkbrzt/rrule/issues/493
  let byweekday: Weekday | Weekday[] = [];
  const _wdOpt = opts._weekday || opts.byweekday;
  if (Array.isArray(_wdOpt)) {
    byweekday =
      _wdOpt?.map((w) => {
        return weekdayFromOpt(w as WeekdayStr);
      }) || [];
  } else if (_wdOpt !== undefined && _wdOpt !== null) {
    byweekday = weekdayFromOpt(_wdOpt);
  }

  let bysetpos: number | null = opts.bysetpos || null;
  if (
    bysetpos !== null &&
    (bysetpos === 0 || bysetpos > 366 || bysetpos < -366)
  ) {
    bysetpos = null;
  }
  // const bySetPos: number | null = (!opts.bysetpos || opts.bysetpos === 0 || opts.bysetpos>366 || opts.bysetpos< -366) ? null : opts.bysetpos;

  const dtstart = startDate;
  if (dtstart) {
    opts.dtstart = new Date(dtstart);
  }

  const until = opts.until ? new Date(opts.until) : undefined;
  const rule = createRRule(
    {
      freq: opts.freq,
      interval: opts.interval,
      count: opts.count,
      dtstart: opts.dtstart,
      byweekno: opts.byweekno,
      bymonthday: opts.bymonthday,
      until,
      bymonth,
      byweekday,
      bysetpos,
    },
    timeZone,
  );
  return rule;
}

// TODO: Test new schedule creation with weekday set and view in edit schedule
function rruleToRecurrenceSection(
  rule: RRule,
): ScheduleCreateForm["_recurrence"] {
  const bymonth = rule.options.bymonth?.map((m) => indexToMonth[m - 1]) || [];
  const byweekday = rule.options.byweekday?.map((w) => w.toString()) || [];
  const bysetpos =
    Array.isArray(rule.options.bysetpos) && rule.options.bysetpos.length
      ? rule.options.bysetpos[0]
      : undefined;

  const opts = rule.options;
  let _ends: EndsType = "never";
  if (opts.count) {
    _ends = "after";
  } else if (opts.until) {
    _ends = "on";
  }

  const until = opts.until ? opts.until.toISOString() : undefined;

  return {
    _ends: _ends,
    _weekday: opts.byweekday?.map((wd) => new Weekday(wd).toString()),
    freq: opts.freq,
    interval: opts.interval,
    count: opts.count,
    until: until,
    dtstart: opts.dtstart,
    byweekno: opts.byweekno,
    bymonthday: opts.bymonthday,
    byyearday: opts.byyearday,
    bymonth,
    byweekday,
    bysetpos,
  };
}

export function createScheduleFormToScheduleType(
  formValues: Partial<ScheduleCreateForm>,
): Omit<ScheduleType, "id"> | undefined {
  const _formValues = structuredClone(formValues);
  if (!_formValues.name || !_formValues.taskPayload) {
    return;
  }

  if (
    _formValues.frequencySimplified === "custom" &&
    !_formValues._recurrence
  ) {
    return;
  }

  if (!_formValues._timeOfDay || !_formValues.timeZone) {
    return;
  }

  const { rrule, startDate } = rruleFromForm({
    startDate: _formValues.startDate,
    _timeOfDay: _formValues._timeOfDay,
    timeZone: _formValues.timeZone,
    _recurrence: _formValues._recurrence,
    frequencySimplified: _formValues.frequencySimplified,
  });

  if (!rrule) {
    return;
  }

  if (_formValues._dueDateUnit && _formValues._dueDateDuration) {
    _formValues.taskPayload.dueDate = dueDateFormToDuration(_formValues);
  }

  return {
    name: _formValues.name,
    startDate: startDate ? startDate.toDate() : new Date(),
    end: _formValues.end,
    nextScheduledDate: null,
    pastScheduledRuns: [],
    timeZone: _formValues.timeZone,
    frequencySimplified: _formValues.frequencySimplified,
    recurrence: rrule.toString(),
    taskType: _formValues.taskType || "form",
    taskPayload: _formValues.taskPayload,
    paused: false,
    pausedUntil: null,
  };
}

export function rruleFromForm(
  values: Pick<
    ScheduleCreateForm,
    | "startDate"
    | "_recurrence"
    | "frequencySimplified"
    | "timeZone"
    | "_timeOfDay"
  >,
): { rrule?: RRule; startDate?: dayjs.Dayjs } {
  const _startDate = values.startDate;
  if (!_startDate) {
    return {};
  }
  /*
   * Keep local time because we want to keep the same date the user selected and override the time
   */
  let startDate = dayjs(_startDate).tz(values.timeZone, true);
  const todParsed = values._timeOfDay
    ? dayjs(values._timeOfDay).tz("UTC")
    : startDate.clone();

  // Time of day here will be an ISO string, we want local time (what the user sees)
  //    to get the expected wall clock time (UTC time of todParsed).
  if (values._timeOfDay && startDate.hour() !== todParsed.hour()) {
    const hour = todParsed.hour();
    const minute = todParsed.minute();
    // Override time with timeOfDay
    startDate = startDate.hour(hour).minute(minute);
  }

  let rrule: RRule | undefined;
  switch (values.frequencySimplified) {
    case "not-repeat":
    case "daily":
    case "weekly":
    case "monthly":
    case "yearly":
    case "weekday": {
      rrule = simpleFreqToRRule(
        startDate.toDate(),
        values.frequencySimplified,
        values.timeZone || dayjs.tz.guess(),
      );
      break;
    }
    case "custom": {
      rrule = recurrenceSectionToRRule(
        startDate.toISOString(),
        values._recurrence,
        values.timeZone,
      );
    }
  }
  return { startDate, rrule };
}

export function scheduleTypeToForm(schedule: ScheduleType): ScheduleCreateForm {
  // Parse rrule
  const rule = rrulestr(schedule.recurrence);
  const recurrenceSection = rruleToRecurrenceSection(rule);
  const duration = schedule.taskPayload.dueDate?.duration;
  const perUser = schedule.taskPayload.formPerUser;
  const startDate = dateOrTimestampAsDate(schedule.startDate);
  const startDateLocal = dayjs(startDate).tz(schedule.timeZone);

  return {
    ...schedule,
    startDate: startDate!.toISOString(),
    frequencySimplified: recurrenceToSimplifiedFreq(
      startDate!,
      rule,
      schedule.timeZone,
    ),
    _assignType: schedule.taskPayload.assignedTo?.length
      ? perUser
        ? "user-each"
        : "user-shared"
      : schedule.taskPayload.assignedToRole
        ? perUser
          ? "role-each"
          : "role-shared"
        : "user-shared",
    _dueDate: duration ? "overdue-after" : "none",
    _dueDateDuration: duration ? durationToQuantity(duration) : undefined,
    _dueDateUnit: duration ? durationToUnit(duration) : undefined,
    _recurrence: recurrenceSection,
    _timeOfDay: startDateLocal.toISOString(),
  };
}

export const FrequencySelectOptions = [
  // [Frequency.MINUTELY.toString(), "Minute"],
  [Frequency.DAILY.toString(), "Day"],
  [Frequency.WEEKLY.toString(), "Week"],
  [Frequency.MONTHLY.toString(), "Month"],
  // [Frequency.QUARTERLY, "Quarter"],
  [Frequency.YEARLY.toString(), "Year"],
].map((v) => ({
  key: v[0].toString(),
  value: v[0],
  label: v[1] as string,
}));

export const WeekdaySelectOptions = [
  [RRule.MO.weekday.toString(), "Monday"],
  [RRule.TU.weekday.toString(), "Tuesday"],
  [RRule.WE.weekday.toString(), "Wednesday"],
  [RRule.TH.weekday.toString(), "Thursday"],
  [RRule.FR.weekday.toString(), "Friday"],
  [RRule.SA.weekday.toString(), "Saturday"],
  [RRule.SU.weekday.toString(), "Sunday"],
].map((v) => {
  return { key: v[0].toString(), value: v[0], label: v[1] as string };
});

export function isScheduleType(schedule: unknown): schedule is ScheduleType {
  return Boolean(
    isObject(schedule) &&
      schedule.id &&
      schedule.name &&
      schedule.recurrence &&
      schedule.startDate &&
      schedule.pastScheduledRuns,
  );
}

export const testOnly = {
  rruleToRecurrenceSection,
};

/*
 * When using the dtstart to determine the first occurence, the rrule library will compare the
 * byweekday, bymonth, byyearday etc settings against the date in UTC, not the expected local time.
 * Example: If a dtstart for a weekly schedule that runs every Friday is a Friday at a time that makes
 * the equivalent UTC datetime land on the subsequent Saturday, the schedule will set the first iteration
 * beginning on the next friday, not on the day of the local dtstart. This is unexpected behaviour
 * and will confusingly only break between ( 24 - offset ) and midnight of a day.
 *
 * The fix:
 * We accept that the rrule library will only process UTC schedules correctly. We convert byweekday,
 * bymonthday, and byyearday options to the UTC equivalent when the time scheduled is within the rollover
 * period (date differs between local and UTC timezones). This will change the nominal values of the scheduled
 * day of week and date values. These can also be translated back if necessary.
 *
 * Important Notes:
 * - Do not call this multiple times on the same rrule, it will corrupt the dtstart and byhour values
 * - byweekday value of RRule.FR for example will be compared internally using instanceof and will fail
 *   across module boundaries (different imports of rrule). Use a string weekday or RRule.FR.weekday instead.
 * - This will break NLP rule to text implementation; this might be fixable with simple find and
 *      replace for weekday names.
 *
 *  Related Github issues:
 *  - https://github.com/jkbrzt/rrule/issues/621
 *  - https://github.com/jkbrzt/rrule/issues/610
 */
export function createRRule(opts: Partial<Options>, timeZone: string) {
  if (dayjs().tz(timeZone).utcOffset() === 0) {
    return new RRule(opts);
  }
  // This should be set regardless
  if (opts?.dtstart) {
    const start = opts.dtstart;

    let startLocal = dayjs(start).tz(timeZone);
    const offsetHours = startLocal.utcOffset() / 60;

    // The time of day in the local timezone where a new day begins in UTC
    const rolloverHour = offsetHours < 0 ? 24 + offsetHours : offsetHours;
    const inWesternRollover =
      offsetHours < 0 && startLocal.hour() >= rolloverHour;
    const inEasternRollover =
      offsetHours > 0 && startLocal.hour() < rolloverHour;
    if (inWesternRollover) {
      // Adjusting the start date and modifying the byhour only works because of an unintended
      // quirk in the internals of rrule library when the byhour is greater than 24. Fixing byhour by %ing the value
      // causes the same issue we were experienceing earlier when we expect it to fix it (misses first iteration).
      if (opts.byweekday) {
        opts.byweekday = shiftWeekdays(opts.byweekday, "next");
      }
      if (opts.bymonthday) {
        opts.bymonthday = shiftMonthDays(opts.bymonthday, "next");
      }
      if (opts.byyearday) {
        opts.byyearday = shiftYearDays(opts.byyearday, "next");
      }
    } else if (inEasternRollover) {
      const rolloverDiff = rolloverHour - startLocal.hour() + 1;
      // TODO: Both of these cases seem to do the same thing. Test and if this works, merge them.
      // Set adjustments shift later into the day so that the UTC and local date match
      startLocal = startLocal.add(rolloverDiff, "h");
      // TODO: Handle negative time
      opts.byhour = startLocal.utc().hour() - rolloverDiff;
      opts.dtstart = startLocal.toDate();
    }
  }
  if (opts?.until) {
    const until = opts.until;

    const untilLocal = dayjs(until).tz(timeZone);
    const offsetHours = untilLocal.utcOffset() / 60;
    until.setHours(untilLocal.hour() + offsetHours);

    opts.until = until;
  }
  return new RRule(opts);
}

function weekdayFromOpt(wd: ByWeekday): Weekday {
  if (typeof wd === "number") {
    return new Weekday(wd);
  } else if (typeof wd === "object") {
    return new Weekday((wd as Weekday).weekday);
  } else {
    return new Weekday(ALL_WEEKDAYS.indexOf(wd));
  }
}

function byWeekdayToInd(wd: ByWeekday) {
  let ind: number;
  // number | WeekdayStr | Weekday
  if (typeof wd === "number") {
    ind = wd;
  } else if (typeof wd === "object") {
    ind = (wd as Weekday).weekday;
  } else {
    ind = ALL_WEEKDAYS.indexOf(wd);
  }
  return ind;
}

function shiftWeekday(wd: ByWeekday, dir: "next" | "prev") {
  const ind = byWeekdayToInd(wd);
  if (dir === "next") {
    return (ind + 1) % 6;
  } else {
    let newInd = ind - 1;
    if (newInd < 0) newInd = 6;
    return newInd;
  }
}

function shiftWeekdays(weekdays: Options["byweekday"], dir: "next" | "prev") {
  if (weekdays === undefined || weekdays === null) {
    return weekdays;
  }

  if (!Array.isArray(weekdays)) {
    // weekdays
    return shiftWeekday(weekdays, dir);
  } else {
    for (let i = 0; i < weekdays.length; i++) {
      weekdays[i] = shiftWeekday(weekdays[i], dir);
    }
    return weekdays;
  }
}

function shiftMonthDays(
  monthDates: Options["bymonthday"], // number | number[] | null
  dir: "next" | "prev",
) {
  if (!monthDates) {
    return monthDates;
  }

  const shiftDate = (date: number): number => {
    let newDate = date;
    if (dir === "next") {
      newDate += 1;
      if (newDate > 31) {
        newDate = 1;
      }
    } else {
      newDate -= 1;
      if (newDate < 1) {
        newDate = 31;
      }
    }
    return newDate;
  };

  if (typeof monthDates === "number") {
    return shiftDate(monthDates);
  } else if (Array.isArray(monthDates)) {
    return monthDates.map(shiftDate);
  }

  return monthDates;
}

function shiftYearDays(
  yearDays: Options["byyearday"], // number | number[] | null
  dir: "next" | "prev",
) {
  if (!yearDays) {
    return yearDays;
  }

  const shiftDay = (day: number): number => {
    let newDay = day;
    if (dir === "next") {
      newDay += 1;
      if (newDay > 365) {
        newDay = 1;
      }
    } else {
      newDay -= 1;
      if (newDay < 1) {
        newDay = 365;
      }
    }
    return newDay;
  };

  if (typeof yearDays === "number") {
    return shiftDay(yearDays);
  } else if (Array.isArray(yearDays)) {
    return yearDays.map(shiftDay);
  }

  return yearDays;
}

export const _forTesting = {
  shiftWeekday,
  shiftWeekdays,
  shiftMonthDays,
  shiftYearDays,
};
