// for vite build
const env = import.meta.env;

// for CRA build
// const env = process.env;

export class Request {
  private baseUrl?: string;
  private accessToken: string;

  constructor(props: { headers?: Headers; baseUrl: string }) {
    if (props.baseUrl) {
      this.baseUrl = props.baseUrl;
    }
    this.accessToken = "";
  }

  private fetch(url: string, options: RequestInit) {
    const headers = new Headers(options.headers);
    this.accessToken &&
      headers.set("Authorization", `Bearer ${this.accessToken}`);

    // set default headers
    headers.set("Content-Type", "application/json");
    headers.set("Accept", "application/json");

    const requestOptions = Object.assign({}, options, { headers });
    const fetchUrl = this.baseUrl ? `${this.baseUrl}${url}` : url;
    return fetch(fetchUrl, requestOptions);
  }

  private tryParseJSON(text: string) {
    try {
      return JSON.parse(text);
    } catch (err) {
      return undefined;
    }
  }

  public async request<T = JSON | string>(
    url: string,
    options: RequestInit,
  ): Promise<[Response, T]> {
    const response = await this.fetch(url, options);

    if (response.status === 401) {
      // handle 401 logout
      // handle redirect and remove authentication
    }

    const bodyText = await response.text();
    let bodyJson = undefined;
    if (response.headers.get("Content-Type")?.includes("application/json")) {
      bodyJson = this.tryParseJSON(bodyText);
    }

    if (!response.ok) {
      throw bodyJson || bodyText;
    } else {
      return [response, bodyJson || bodyText];
    }
  }

  public setAccessToken(token?: string): void {
    this.accessToken = token || "";
  }
}

const allowProxying =
  env.REACT_APP_ALLOW_PROXY && env.REACT_APP_ALLOW_PROXY === "true";
const usingProxy =
  allowProxying &&
  typeof window !== "undefined" &&
  window.location.port === "8000";
export default new Request({
  baseUrl: usingProxy
    ? `${window.location.origin}/wombat-development/us-central1`
    : `${env.REACT_APP_API_URL}`,
});
