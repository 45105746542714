import {
  IClientTarget,
  ISiteTarget,
  ITenantTarget,
  isSiteTarget,
  isTenantTarget,
} from "../typings";

/**
 * generate FE display name from user AllReference
 */
export function displayUserName(obj: undefined): undefined;
export function displayUserName(obj?: {
  firstName?: string;
  lastName?: string;
  displayName?: string;
  metadata?: { firstName?: string; lastName?: string };
}): string;
export function displayUserName(
  obj?:
    | {
        firstName?: string;
        lastName?: string;
        displayName?: string;
        metadata?: { firstName?: string; lastName?: string };
      }
    | undefined,
): string | undefined {
  if (!obj) {
    return undefined;
  }

  return `${obj.metadata?.lastName || obj.lastName}, ${
    obj.displayName || obj.metadata?.firstName || obj.firstName
  }`;
}

/**
 * THIS CAN NEVER CHANGE
 * formatting here is stored in the database. So changes will break things currently saved
 */
export function getGlobalUserAccessKey(
  target: IClientTarget | ISiteTarget | ITenantTarget,
): string {
  if (isTenantTarget(target)) {
    const { divisionId, tenantId } = target;
    if (divisionId) {
      return `_t::${tenantId}::${divisionId}`;
    }
    return `_t::${tenantId}`;
  }

  if (isSiteTarget(target)) {
    return "_s::" + target.siteId;
  }

  const { orgId, tenantId, divisionId } = target;
  if (divisionId && tenantId) {
    return `${orgId}::${tenantId}::${divisionId}`;
  }
  if (tenantId) {
    return `${orgId}::${tenantId}`;
  }
  return `${orgId}`;
}

export function getTargetFromAccessKey(
  key: string,
): IClientTarget | ISiteTarget | ITenantTarget {
  const [orgId, tenantId, divisionId] = key.split("::");

  if (orgId === "_s") {
    // key will be _s::siteId => orgId == "_s" and tenantId == siteId
    return { siteId: tenantId };
  } else if (orgId === "_t") {
    // key will be _t::tenantId::divisionId
    return { tenantId, divisionId };
  } else {
    return { orgId, tenantId, divisionId };
  }
}

/**
 * Returns the "leaf" client id
 * eg:
 * - division then it will be the divisionId
 * - site => siteId
 */
export function targetLeafId(
  target: IClientTarget | ISiteTarget | ITenantTarget,
) {
  if (isTenantTarget(target)) {
    return target.divisionId || target.tenantId;
  }

  if (isSiteTarget(target)) {
    return target.siteId;
  }

  return target.divisionId || target.tenantId || target.orgId;
}

export function targetIds(
  target: IClientTarget | ISiteTarget | ITenantTarget,
): string[] {
  if (isTenantTarget(target)) {
    // @todo check if this can just be "t"
    return ["t", target.tenantId, target.divisionId!].filter(Boolean);
  }

  if (isSiteTarget(target)) {
    // @todo: see if we can nuke all sites and have this not be a problem to migrate to ["s", siteId]
    // return ["s", target.siteId];
    return [target.siteId];
  }

  return [target.orgId!, target.tenantId!, target.divisionId!].filter(Boolean);
}

/**
 * uniqueTargetString split by "_" not "-" because target ids can have dashes
 */

export function getTargetFromUniqueTargetString(
  key: string,
): ITenantTarget | IClientTarget | ISiteTarget {
  const ids = key.split("_");
  if (ids[0] === "s") {
    return { siteId: ids[1] };
  }
  if (ids[0] === "t") {
    return { tenantId: ids[1], divisionId: ids[2] || undefined };
  }
  return {
    orgId: ids[0],
    tenantId: ids[1] || undefined,
    divisionId: ids[2] || undefined,
  };
}

export function uniqueTargetString(
  target: IClientTarget | ISiteTarget | ITenantTarget,
): string {
  if (isTenantTarget(target)) {
    return ["t", target.tenantId, target.divisionId!].filter(Boolean).join("_");
  }

  if (isSiteTarget(target)) {
    return `s_${target.siteId}`;
  }

  return [target.orgId!, target.tenantId!, target.divisionId!]
    .filter(Boolean)
    .join("_");
}
