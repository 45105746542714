/* eslint-disable @typescript-eslint/no-explicit-any */
import { Timestamp } from "@google-cloud/firestore";

export type Entity<T = number> = {
  id: string;
  createdAt: T;
  removedAt?: T | null;
  updatedAt?: T;
};

export interface ITenantTarget {
  tenantId: string;
  divisionId?: string;
}
export interface IConsultantTarget {
  orgId: string;
}

export type IClientTarget =
  | {
      orgId: string;
      tenantId?: string;
      divisionId?: string;
    }
  // | IConsultantTarget
  | ITenantTarget;

export interface ISiteTarget {
  siteId: string;
}

export function isSiteTarget(target?: unknown): target is ISiteTarget {
  return Boolean(
    target &&
      typeof target == "object" &&
      (target as any).siteId &&
      !(target as any).orgId &&
      typeof (target as any).siteId === "string",
  );
}

export function isTenantTarget(target?: unknown): target is ITenantTarget {
  return Boolean(
    target &&
      typeof target === "object" &&
      !(target as any).orgId &&
      (target as any).tenantId &&
      typeof (target as any).tenantId === "string",
  );
}

export function isClientTarget(target?: unknown): target is {
  orgId: string;
  tenantId?: string;
  divisionId?: string;
} {
  return Boolean(
    target &&
      typeof target == "object" &&
      !(target as any).siteId &&
      (((target as any).orgId && typeof (target as any).orgId === "string") ||
        isTenantTarget(target)),
  );
}

export function targetsAreEqual(
  a?: IClientTarget | ISiteTarget,
  b?: IClientTarget | ISiteTarget,
): boolean {
  if (a && b && isTenantTarget(a) && isTenantTarget(b)) {
    return a.tenantId === b.tenantId && a.divisionId === b.divisionId;
  }

  if (
    a &&
    b &&
    isClientTarget(a) &&
    isClientTarget(b) &&
    !isTenantTarget(b) &&
    !isTenantTarget(a)
  ) {
    return (
      a.orgId === b.orgId &&
      b.tenantId === a.tenantId &&
      a.divisionId === b.divisionId
    );
  }
  if (a && b && isSiteTarget(a) && isSiteTarget(b)) {
    return a.siteId === b.siteId;
  }

  if (a === b && a === undefined) {
    return true;
  }
  return false;
}

export type TEntityMetadata = Record<string, string>;

// eslint-disable-next-line no-shadow
export enum EntityStatus {
  "inactive" = "inactive",
  "active" = "active",
}

export interface IWombatResponse {
  statusCode: number;
  message: string;
}
export interface IWombatErrorResponse extends IWombatResponse {
  status: "error";
  errors?: IValidationError[];
  data?: object;
}

export interface IWombatSuccessResponse<
  T = Record<string, unknown> | string | number | boolean | unknown,
> extends IWombatResponse {
  data: T;
}

export interface IValidationError {
  message: string;
  path?: string;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type EditorDocType = any;

export function isTimestamp(object: unknown): object is Timestamp {
  if (
    object &&
    typeof object === "object" &&
    Object.getOwnPropertyNames(object).includes("_seconds") &&
    Object.getOwnPropertyNames(object).includes("_nanoseconds") &&
    (object as Record<string, unknown>)?._seconds !== undefined &&
    (object as Record<string, unknown>)?._nanoseconds !== undefined
  ) {
    return true;
  }
  return false;
}

export interface HasNext {
  offset: number;
  limit: number;
}
