import React from "react";
import { createRoot } from "react-dom/client";
import * as Sentry from "@sentry/react";
import "./services/logging-browser-api";
// MainApp
import App from "./App";

import "./services/firebase.service";
import "./scss/index.scss";
import { devOnly } from "./utilities/devOnly";

const container = document.getElementById("root");

const currentTheme = localStorage.getItem("theme");
if (currentTheme === "light") {
  document.querySelector("html")?.classList.remove("dark-theme");
} else {
  document.querySelector("html")?.classList.add("dark-theme");
}

// Sentry setup
if (import.meta.env.REACT_APP_SENTRY_DSN) {
  Sentry.init({
    environment: devOnly() ? "dev" : "prod",
    release: import.meta.env.REACT_APP_RELEASE,
    dsn: import.meta.env.REACT_APP_SENTRY_DSN,
    integrations: [
      Sentry.browserTracingIntegration(),
      Sentry.replayIntegration(),
      Sentry.browserProfilingIntegration(),
    ],

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    tracesSampleRate: 1.0,

    // Set `tracePropagationTargets` to control for which URLs distributed tracing should be enabled
    // tracePropagationTargets: [/^\//, /^https:\/\/app\.wombat\.software/],

    // Capture Replay for 10% of all sessions,
    // plus for 100% of sessions with an error
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0,

    // Set profilesSampleRate to 1.0 to profile every transaction.
    // Since profilesSampleRate is relative to tracesSampleRate,
    // the final profiling rate can be computed as tracesSampleRate * profilesSampleRate
    // For example, a tracesSampleRate of 0.5 and profilesSampleRate of 0.5 would
    // result in 25% of transactions being profiled (0.5*0.5=0.25)
    profilesSampleRate: 0.03,
  });
}

// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
const root = createRoot(container!);

root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
);
