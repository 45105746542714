// constants
import { ADDRESS_FORM_FIELDS } from "./address-form";

// types
import { IFieldGroupBuilt, IFieldTypes } from "../../typings";

export const EDIT_ORG_FORM: IFieldGroupBuilt = {
  name: "editOrganization",
  label: "Company Name",

  groups: [
    {
      name: "org",
      label: "Basic Information",
      isSection: true,
      fields: [
        {
          name: "name",
          span: 1,
          label: "Comapny Name",
          fieldType: IFieldTypes.textField,
        },
        {
          name: "id",
          span: 1,
          label: "Short Code",
          fieldType: IFieldTypes.textField,
          readonly: true,
        },
        {
          name: "local",
          label: "Data Residency",
          span: 1,
          readonly: true,
          fieldType: IFieldTypes.select,
          defaultValue: ["us"],
          options: [
            {
              label: "Canada",
              value: "ca",
            },
            {
              label: "United States",
              value: "us",
            },
          ],
        },
        {
          name: "timeZone",
          span: 1,
          label: "Time Zone",
          fieldType: IFieldTypes.timezone,
        },
      ],
    },
    {
      ...ADDRESS_FORM_FIELDS,
      name: "org.address",
      min: 1,
      max: 1,
      label: "Head Office Address",
    },
    {
      name: "org.contactInformation",
      label: "Contact Information",
      min: 1,
      max: 1,
      fields: [
        {
          fieldType: IFieldTypes.textField,
          span: 1,
          name: "name",
          label: "Primary Contact Full Name",
        },
        {
          name: "email",
          span: 1,
          label: "Email",
          fieldType: IFieldTypes.emailField,
        },
        {
          name: "officePhone",
          span: 1,
          label: "Office Phone",
          fieldType: IFieldTypes.phoneField,
        },
        {
          name: "mobilePhone",
          span: 1,
          label: "Mobile Phone",
          fieldType: IFieldTypes.phoneField,
        },
      ],
    },
  ],
};
