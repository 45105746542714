import { Frequency, RRule, Weekday } from "rrule";
import { createRRule, ScheduleCreateForm } from "./scheduler";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import tz from "dayjs/plugin/timezone";
dayjs.extend(utc);
dayjs.extend(tz);

export function simpleFreqToRRule(
  startDate: Date | undefined,
  frequencyPresetString: ScheduleCreateForm["frequencySimplified"],
  timeZone: string,
) {
  if (!startDate) {
    return undefined;
  }

  switch (frequencyPresetString) {
    case "not-repeat":
      return createRRule(
        {
          dtstart: startDate,
          freq: Frequency.DAILY,
          count: 1,
        },
        timeZone,
      );
    case "daily":
      return dailyFreq(startDate, timeZone);
    case "weekly":
      return weeklyDowFreq(startDate, timeZone);
    case "monthly":
      return monthlyOnFirstDowFreq(startDate, timeZone);
    case "yearly":
      return yearlyMonthDateFreq(startDate, timeZone);
    case "weekday":
      return weekdayFreq(startDate, timeZone);
    case "custom":
      return undefined;
  }
}

function dailyFreq(dt: Date, timeZone: string) {
  return createRRule(
    {
      dtstart: dt,
      freq: Frequency.DAILY,
    },
    timeZone,
  );
}

function weeklyDowFreq(dt: Date, timeZone: string) {
  let dayInd = dayjs(dt).tz(timeZone).day() - 1;
  if (dayInd === -1) dayInd = 6;
  const rule = createRRule(
    {
      dtstart: dt,
      freq: Frequency.WEEKLY,
      byweekday: dayInd,
    },
    timeZone,
  );
  return rule;
}

function monthlyOnFirstDowFreq(dt: Date, timeZone: string) {
  const wd = new Weekday(dt.getDay() - 1);
  return createRRule(
    {
      dtstart: dt,
      freq: Frequency.MONTHLY,
      byweekday: wd,
      bysetpos: 1,
    },
    timeZone,
  );
}

function yearlyMonthDateFreq(dt: Date, timeZone: string) {
  let dayInd = dayjs(dt).tz(timeZone).day() - 1;
  if (dayInd === -1) dayInd = 6;
  const wd = new Weekday(dayInd);
  return createRRule(
    {
      dtstart: dt,
      freq: Frequency.YEARLY,
      bymonth: dt.getMonth(),
      byweekday: wd,
    },
    timeZone,
  );
}

function weekdayFreq(dt: Date, timeZone: string) {
  return createRRule(
    {
      dtstart: dt,
      freq: Frequency.WEEKLY,
      byweekday: [RRule.MO, RRule.TU, RRule.WE, RRule.TH, RRule.FR],
    },
    timeZone,
  );
}

type SimpleFreqValue = ScheduleCreateForm["frequencySimplified"];

export function recurrenceToSimplifiedFreq(
  startDate: Date,
  rule: RRule,
  timeZone: string,
): SimpleFreqValue {
  // Generate inverse to see if the rrules match
  switch (rule.options.freq) {
    case Frequency.DAILY: {
      const _simple = simpleFreqToRRule(startDate, "daily", timeZone);
      if (_simple?.toString() === rule.toString()) {
        return "daily";
      }
      const _simpleNotRepeat = simpleFreqToRRule(
        startDate,
        "not-repeat",
        timeZone,
      );
      if (_simpleNotRepeat?.toString() === rule.toString()) {
        return "not-repeat";
      }
      return "custom";
    }
    case Frequency.WEEKLY: {
      const _simple = simpleFreqToRRule(startDate, "weekly", timeZone);
      if (_simple?.toString() === rule.toString()) {
        return "weekly";
      }
      const _simpleWeekday = simpleFreqToRRule(startDate, "weekday", timeZone);
      if (_simpleWeekday?.toString() === rule.toString()) {
        return "weekday";
      }
      return "custom";
    }
    case Frequency.MONTHLY: {
      const _simple = simpleFreqToRRule(startDate, "monthly", timeZone);
      if (_simple?.toString() === rule.toString()) {
        return "monthly";
      }
      return "custom";
    }
    case Frequency.YEARLY: {
      const _simple = simpleFreqToRRule(startDate, "yearly", timeZone);
      if (_simple?.toString() && rule.toString()) {
        return "yearly";
      }
      return "custom";
    }
    default: {
      return "custom";
    }
  }
}
